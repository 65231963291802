import decMontantsFields from "./decMontantsFields"

export default [
    ...decMontantsFields,
    "titre",
    "statut",
    "num",
    "numDevis",
    "numCommande",

    "agence",
    "client",
    "affaire",

    "prixVenteHT",
    "tauxTva",
    
    "dateValidationChargeAffaire",
    "dateValidationComptabilite",
    "dateValidationDirection",
    "dateFin",
    "dateDebut",

    "userValidationChargeAffaire",
    "userValidationComptabilite",
    "userValidationDirection"
]
