<template>
    <div>
        <loading-card v-if="dec == null || saving || deleting || statut == null || tauxTva == null">
            <template v-if="saving">Enregistrement en cours ...</template>
            <template v-else-if="deleting">Suppression en cours ...</template>
            <template v-else>Chargement du dec ...</template>
        </loading-card>

        <b-form v-else @keydown.enter.prevent>
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col lg="12">
                        <b-form-group 
                            label-for="titre"
                            label-cols-lg="3"
                            label-cols-sm="12"
                            label-size="lg"
                        >
                            <div slot="label">
                               DEC n° <b>{{dec.num != null && dec.num != "" ? dec.num : '-'}}</b>
                            </div>
                            <validation-provider #default="{ errors }" name="Titre" rules="required">
                                <b-form-input 
                                    v-model="titre"
                                    id="titre"
                                    placeholder="Titre"
                                    size="lg"
                                    :state="errors.length > 0 ? false : null"
                                    :disabled="!isBrouillon"
                                />
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>

                <!-- INFORMATIONS -->
                <b-card title="Informations" class="card-info">
                    <b-row>
                        <b-col lg="4" sm="6">
                            <b-form-group label="Agence" label-for="agence">
                                <validation-provider #default="{ errors }" name="Agence" rules="required">
                                    <v-select
                                        v-model="agence"
                                        :options="agences"
                                        :clearable="false"
                                        :disabled="!isBrouillon"
                                        :filterable="true"
                                        :filter="filterAgences"
                                        :class="errors.length > 0 ? 'danger' : ''"
                                        placeholder="Rechercher une agence"
                                    >
                                        <template #no-options="{search, loading}">
                                            {{search.length < 2 ? "Rechercher une agence" : "Aucune agence correspondante"}}
                                        </template>
                                        <template #option="{numero, nom}">{{numero}} - {{nom}}</template>
                                        <b-spinner  small slot="spinner" slot-scope="{loading}" v-show="loading" type="grow" variant="primary"/>
                                        <template #selected-option="{numero, nom}">{{numero}} - {{nom}}</template>
                                    </v-select>
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col lg="4" sm="6">
                            <b-form-group label="N° Devis" label-for="numDevis" >
                                <validation-provider #default="{ errors }" name="N° Devis" :rules="validation ? 'required' : ''">
                                    <b-form-input 
                                        v-model="numDevis"
                                        id="numDevis"
                                        placeholder="N° Devis"
                                        :disabled="!isBrouillon"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col lg="4" sm="6">
                            <b-form-group label="Date" label-for="dateValidationChargeAffaire" title="Générée automatiquement lors de la validation par le chargé d'affaire">
                                <validation-provider #default="{ errors }" name="Date">
                                    <b-form-input 
                                        v-model="dateValidationChargeAffaire"
                                        id="dateValidationChargeAffaire"
                                        placeholder="Date"
                                        :state="errors.length > 0 ? false : null"
                                        disabled
                                    />
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col lg="4" sm="6">
                            <b-form-group label="Client" label-for="client">
                                <validation-provider #default="{ errors }" name="Client" rules="required">
                                    <v-select
                                        v-model="client"
                                        :options="clients"
                                        :disabled="!isBrouillon"
                                        :filter="filterClients"
                                        :clearable="false"
                                        :filterable="true"
                                        :class="errors.length > 0 ? 'danger' : ''"
                                        placeholder="Sélectionner un client"
                                    >
                                        <template #no-options="{search, loading}">
                                            {{"Aucun client correspondant"}}
                                        </template>
                                        <template #option="{nom}">{{nom}}</template>
                                        <b-spinner  small slot="spinner" slot-scope="{loading}" v-show="loading" type="grow" variant="primary"/>
                                        <template #selected-option="{nom}">{{nom}}</template>
                                    </v-select>
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col lg="4" sm="6">
                            <b-form-group label="N° Commande" label-for="numCommande">
                                <validation-provider #default="{ errors }" name="N° Commande" :rules="validation ? 'required' : ''">
                                    <b-form-input 
                                        v-model="numCommande"
                                        id="numCommande"
                                        placeholder="N° Commande"
                                        :disabled="!isBrouillon"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col lg="2" sm="3">
                            <b-form-group label="Date Début" label-for="dateDebut">
                                <validation-provider #default="{ errors }" name="Date Début" :rules="validation ? 'required' : ''" vid="datedebut">
                                    <b-form-datepicker
                                        locale="fr" 
                                        v-model="dateDebut"
                                        :disabled="!isBrouillon"
                                        id="dateDebut"
                                        placeholder="Date Début"
                                        :date-format-options="{day: '2-digit', year: 'numeric', month: '2-digit'}"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col lg="2" sm="3">
                            <b-form-group label="Date Fin" label-for="dateFin">
                                <validation-provider #default="{ errors }" name="Date Fin" :rules="validation ? {required: true, after:['@datedebut']} : ''">
                                    <b-form-datepicker
                                        locale="fr" 
                                        v-model="dateFin"
                                        :disabled="!isBrouillon"
                                        id="dateFin"
                                        placeholder="Date Fin"
                                        :date-format-options="{day: '2-digit', year: 'numeric', month: '2-digit'}"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col col-lg="6">
                            <b-form-group label="Affaire" :title="getAffaireTitle" label-for="affaire">
                                <validation-provider #default="{ errors }" name="Affaire" :rules="!createNewAffaire && validation ? 'required' : ''">
                                    <v-select
                                        v-if="!createNewAffaire"
                                        v-model="affaire"
                                        :options="affairesMatching"
                                        :clearable="false"
                                        :filterable="true"
                                        :filter="filterAffaires"
                                        :selectable="(option) => option.statut.code == 'ENCOURS'"
                                        :class="errors.length > 0 ? 'danger' : ''"
                                        placeholder="Sélectionner une affaire"
                                        :disabled="client == null || !isBrouillon"
                                        inline
                                    >
                                        <template #no-options="{search, loading}">
                                            {{"Aucune affaire correspondante"}}
                                        </template>
                                        <template #option="{num, titre, statut}">{{num}} - {{titre}} {{statut.code != 'ENCOURS' ? ` - ${statut.libelle}` : ''}}</template>
                                        <b-spinner  small slot="spinner" slot-scope="{loading}" v-show="loading" type="grow" variant="primary"/>
                                        <template #selected-option="{num, titre}">{{num}} - {{titre}}</template>
                                    </v-select>
                                    <b-form-input disabled value="Nouvelle Affaire" v-else/>
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col>
                            <b-form-group 
                                label="Créer une nouvelle affaire"
                                :title="getAffaireTitle"
                            >
                                <b-form-checkbox
                                    v-model="createNewAffaire"
                                    class="mr-0 mt-50"
                                    name="is-rtl"
                                    switch
                                    inline
                                    :disabled="client == null || !isBrouillon"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card>



                <!-- DEVIS -->
                <b-card title="Devis" class="card-other">
                    <b-row>
                        <b-col lg="3" sm="6">
                            <b-form-group label="Prix Vente HT" label-for="prixVenteHT">
                                <validation-provider #default="{ errors }" name="Prix Vente HT" rules="required">
                                    <b-input type="number"
                                        id="prixVenteHT"
                                        v-model="prixVenteHT"
                                        class=" text-right"
                                        :class="errors.length > 0 ? 'is-invalid' : ''"
                                        
                                        placeholder="0,00"
                                        :disabled="!isBrouillon"
                                    />
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col lg="3" sm="6">
                            <b-form-group label="Taux TVA" label-for="tauxTva">
                                <validation-provider #default="{ errors }" name="Taux TVA" rules="required">
                                    <v-select
                                        v-model="tauxTva"
                                        :options="tauxTvas"
                                        :clearable="false"
                                        :disabled="!isBrouillon"
                                        :filterable="false"
                                        :class="errors.length > 0 ? 'danger' : ''"
                                    >
                                        <template #option="{taux}">{{parseFloat(taux).toFixed(2)}} %</template>
                                        <b-spinner  small slot="spinner" slot-scope="{loading}" v-show="loading" type="grow" variant="primary"/>
                                        <template #selected-option="{taux}">{{parseFloat(taux).toFixed(2)}} %</template>
                                    </v-select>
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col lg="3" sm="6">
                            <b-form-group label="TVA" label-for="tva">
                                <b-input
                                    id="tva"
                                    :value="formatNumber(tva)"
                                    class=" text-right"
                                    :raw="false"
                                    
                                    placeholder="0,00"
                                    :disabled="true"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col lg="3" sm="6">
                            <b-form-group label="Prix Vente TTC" label-for="prixVenteTTC">
                                <b-input
                                    id="number"
                                    :value="formatNumber(prixVenteTTC)"
                                    class=" text-right"
                                    :raw="false"
                                    
                                    placeholder="0,00"
                                    :disabled="true"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card>


                <!-- DECOMPOSITION DU DEC -->
                <dec-montants 
                    :values="dataMontantsEdit"
                    :title="'Décomposition du DEC'"
                    @update="updateMontant"
                    :disableFields="!isBrouillon"
                    :formRules="validation ? 'required' : ''"
                    
                />

                <b-row class="align-items-center">
                    <b-col lg="6" xs="12" class="align-middle">
                        <b-card class="card-other">
                            <b-row>
                                <b-col xs="7">
                                    <b>Total Prix Revient / Deboursé Total</b>
                                </b-col>
                                <b-col xs="5" class="text-right">
                                    <b class="text-right pr-2">{{formatNumber(totalPrixRevient)}}</b>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>

                    <b-col lg="6" xs="12">
                        <b-card class="card-other">
                            <b-row>
                                <b-col xs="7">
                                    <b>Prix de vente Initial</b>
                                </b-col>
                                <b-col xs="5" class="text-right">
                                    <b>{{formatNumber(prixVenteInitial)}}</b>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col xs="7">
                                    <b>Marge Brute en €</b>
                                </b-col>
                                <b-col xs="5" class="text-right">
                                    <b>{{formatNumber(margeEuros)}}</b>
                                </b-col>
                            </b-row>
                            <b-row>
                            <b-col xs="7">
                                    <b>Marge Brute en %</b>
                                </b-col>
                                <b-col xs="5" class="text-right">
                                    <b>{{formatNumber(margePourcent, "%")}}</b>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>

                <dec-montants 
                    :values="dataMontantsEditHorsDebours"
                    :title="'Divers'"
                    @update="updateMontant"
                    :showHeader="false"
                    :disableFields="!isBrouillon"
                    :extraColumns="[{target: 'pourcentage', style: {width: '75px !important'}, prefix: '%'}]"
                />

                <b-card class="card-other">
                    <b-row>
                        <b-col xs="7">
                            <b>Prix de vente</b>
                        </b-col>
                        <b-col xs="5" class="text-right">
                            <b>{{formatNumber(isNaN(parseFloat(prixVenteHT)) ? 0 : parseFloat(prixVenteHT))}}</b>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="7">
                            <b>Marge Brute en €</b>
                        </b-col>
                        <b-col xs="5" class="text-right">
                            <b>{{formatNumber(margeBaseEuros)}}</b>
                        </b-col>
                    </b-row>
                    <b-row>
                    <b-col xs="7">
                            <b>Marge Brute en %</b>
                        </b-col>
                        <b-col xs="5" class="text-right">
                            <b>{{formatNumber(margeBasePourcent, "%")}}</b>
                        </b-col>
                    </b-row>
                </b-card>

                <b-card title="Documents Liés (à minima : commande client, devis et feuille de prix)" v-if="!creating" class="card-other">
                    <b-row>
                        <b-col sm="10">
                            <b-form-file
                                id="file2Add"
                                name="file2Add"
                                v-model="file2Add"
                                placeholder="Sélectionner un fichier ou glisser le ici..."
                                />
                            
                        </b-col>
                        <b-col sm="2" class="text-center">
                            <b-button variant="primary" @click.prevent="addFile" :disabled="file2Add == null">Ajouter</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <ul>
                            <li v-for="doc in linkedDocuments" :key="doc.id">
                                <a :href="getDocUrl(doc)" target="_blank">{{doc.nom}}</a>
                                <feather-icon icon="XIcon" class="ml-50 mr-50" :style="{cursor: 'pointer'}" @click="confirmDeleteDocument(doc.id, doc.nom, removeDocument, false)"/>
                            </li>
                        </ul>
                    </b-row>
                </b-card>

                <b-card title="Validation" class="card-other">
                    <b-row>
                        <b-col lg="4" sm="6">
                            <b-form-group label="Chargé d'affaire" label-for="userValidationChargeAffaire">
                                <validation-provider #default="{ errors }" name="Chargé Affaire" rules="required">
                                    <b-row>
                                        <b-col lg="8">
                                            <v-select
                                                v-model="userValidationChargeAffaire"
                                                :options="usersChargeAffaireMatching"
                                                :clearable="false"
                                                :filterable="true"
                                                :filter="filterUsers"
                                                :class="errors.length > 0 ? 'danger' : ''"
                                                :disabled="reopen || !isBrouillon || (showDecsOnlyUser && userValidationChargeAffaire != null && user.id == userValidationChargeAffaire.id)"
                                            >
                                                <template #no-options="{search, loading}">
                                                    {{"Rechercher un utilisateur"}}
                                                </template>
                                                <template #option="{firstname, lastname, username}">{{firstname}} {{lastname}} - {{username}}</template>
                                                <b-spinner  small slot="spinner" slot-scope="{loading}" v-show="loading" type="grow" variant="primary"/>
                                                <template #selected-option="{firstname, lastname, username}">{{firstname}} {{lastname}} - {{username}}</template>
                                            </v-select>
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </b-col>
                                        <b-col lg="4">
                                            <b-button :disabled="userValidationChargeAffaire == null" variant="gradient-success" type="submit" @click.prevent="validationForm('CHARGE_AFFAIRE')" v-if="!reopen && isBrouillon && (isGranted('ROLE_DIRECTION') || isGranted('ROLE_COMPTABILITE'))">
                                                <span class="align-middle">Valider</span>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col lg="4" sm="6">
                            <b-form-group label="Comptabilité" label-for="userValidationComptabilite">
                                <validation-provider #default="{ errors }" name="Comptabilité">
                                    <b-row>
                                        <b-col lg="8">
                                            <v-select
                                                v-model="userValidationComptabilite"
                                                :options="usersComptabiliteMatching"
                                                :clearable="false"
                                                :filterable="true"
                                                :filter="filterUsers"
                                                :class="errors.length > 0 ? 'danger' : ''"
                                                :disabled="reopen || !(statut.code == 'CHARGE_AFFAIRE' && isGranted('ROLE_COMPTABILITE'))"
                                            >
                                                <template #no-options="{search, loading}">
                                                    {{"Rechercher un utilisateur"}}
                                                </template>
                                                <template #option="{firstname, lastname, username}">{{firstname}} {{lastname}} - {{username}}</template>
                                                <b-spinner  small slot="spinner" slot-scope="{loading}" v-show="loading" type="grow" variant="primary"/>
                                                <template #selected-option="{firstname, lastname, username}">{{firstname}} {{lastname}} - {{username}}</template>
                                            </v-select>
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </b-col>
                                        <b-col lg="4">
                                            <span v-if="dateValidationComptabilite != null" class="align-middle">{{dateValidationComptabilite}}</span>
                                            <b-button :disabled="userValidationComptabilite == null" variant="gradient-success" type="submit" @click.prevent="validationForm('COMPTABILITE')" v-else-if="!reopen && statut.code == 'CHARGE_AFFAIRE' && isGranted('ROLE_COMPTABILITE')">
                                                <span class="align-middle">Valider</span>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col lg="4" sm="6">
                            <b-form-group label="Direction" label-for="userValidationDirection">
                                <validation-provider #default="{ errors }" name="Direction">
                                    <b-row>
                                        <b-col lg="8">
                                            <v-select
                                                v-model="userValidationDirection"
                                                :options="usersDirectionMatching"
                                                :clearable="false"
                                                :filterable="true"
                                                :filter="filterUsers"
                                                :class="errors.length > 0 ? 'danger' : ''"
                                                :disabled="reopen || !(statut.code == 'COMPTABILITE'  && (isGranted('ROLE_DIRECTION') || isGranted('ROLE_CAN_VALIDATE_FOR_DIRECTION')))"
                                            >
                                                <template #no-options="{search, loading}">
                                                    {{"Rechercher un utilisateur"}}
                                                </template>
                                                <template #option="{firstname, lastname, username}">{{firstname}} {{lastname}} - {{username}}</template>
                                                <b-spinner  small slot="spinner" slot-scope="{loading}" v-show="loading" type="grow" variant="primary"/>
                                                <template #selected-option="{firstname, lastname, username}">{{firstname}} {{lastname}} - {{username}}</template>
                                            </v-select>
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </b-col>
                                        <b-col lg="4">
                                            <span v-if="dateValidationDirection != null" class="pt-1">{{dateValidationDirection}}</span>
                                            <b-button :disabled="userValidationDirection == null" variant="gradient-success" type="submit" @click.prevent="validationForm('DIRECTION')" v-else-if="!reopen && statut.code == 'COMPTABILITE'  && (isGranted('ROLE_DIRECTION') || isGranted('ROLE_CAN_VALIDATE_FOR_DIRECTION'))">
                                                <span class="align-middle">Valider</span>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>

                    </b-row>
                </b-card>

                <b-row>
                    <b-col sm="12" lg="6" md="8">
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="goBack" class="mr-1">
                            <feather-icon icon="ListIcon" class="mr-50"/>
                            <span class="align-middle">Retour</span>
                        </b-button>

                        <b-button variant="gradient-success" type="submit" @click.prevent="validationForm('')" class="mr-1" v-if="isBrouillon || reopen">
                            <feather-icon icon="CheckIcon" class="mr-50"/>
                            <span class="align-middle">{{creating ? "Créer" : "Enregistrer"}}</span>
                        </b-button>

                        <b-button variant="gradient-success" class="mr-1" type="submit" @click.prevent="validationForm('CHARGE_AFFAIRE')" v-if="isBrouillon && isChargeAffaire && showDecsOnlyUser">
                            <feather-icon icon="CheckIcon" class="mr-50"/>
                            <span class="align-middle">Valider</span>
                        </b-button>

                        <b-button variant="gradient-primary" target="_blank" v-if="decReouvrable && !reopen" class="mr-1" @click.prevent="reopenDec">
                            <span class="align-middle">Réouvrir</span>
                        </b-button>

                         <b-button variant="gradient-primary" type="submit" @click.prevent="downloadFile(exportUrl, `dec_${dec.num}.xlsx`)" v-if="dec != null && !creating">
                            <feather-icon icon="CheckIcon" class="mr-50"/>
                            <span class="align-middle">Exporter</span>
                        </b-button>
                    </b-col>

                    <b-col sm="12" lg="6" md="4" :class="deleteClass" v-if="!creating && isBrouillon && (!showDecsOnlyUser || isChargeAffaire)">
                        <b-button variant="gradient-danger" @click="confirmDeleteDec(dec.id, dec.num, goBack)">
                            <feather-icon icon="TrashIcon" class="mr-50"/>
                            <span class="align-middle">Supprimer</span>
                        </b-button>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-form>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex"
    import LoadingCard from "@/views/GDLoadingCard"
    import decApi from "@/api/decApi"
    import {computedFactory, storeComputedFactory} from "@/services/computedFactory"
    import { computed, ref } from '@vue/composition-api'
    import { confirmDeleteDec, deleteDec } from "@/views/setups/dec"
    import decMontantsMixin from "@/views/setups/decMontantsMixin"
    import { confirmDeleteDocument, deleteDocument } from "@/views/setups/document"
    import expectedFields from "./decFields"
    import decMontantsFields from "./decMontantsFields"

    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import { after } from '@/libs/validations'
    import vSelect from 'vue-select'
    import _ from "lodash"

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import Cleave from 'vue-cleave-component'

    import clientApi from "@/api/clientApi"
    import agenceApi from "@/api/agenceApi"
    import affaireApi from "@/api/affaireApi"
    import miscApi from "@/api/miscApi"
    import utilisateurApi from "@/api/utilisateurApi"
    import docApi from "@/api/documentApi"
    import {APP_URL, downloadFile} from "@/api/api"

    import {formatNumber} from "@/services/utils"
    import {filterArray} from "@/services/utils"

    import DecMontants from "./DecMontants.vue"
    
    export default {
        mixins: [decMontantsMixin],
        components: {
            LoadingCard,
            ValidationProvider,
            ValidationObserver,
            ToastificationContent,
            vSelect,
            Cleave,
            DecMontants
        },

        watch: {
            client() {this.affaire = null; this.affairesMatching = [], this.refreshAffaires()},
            agence() {this.affaire = null; this.affairesMatching = []},
            createNewAffaire() { if (this.createNewAffaire ) {this.affaire = null}}
        },

        data() {
            return {
                reopen: false,
                plafondAffaire: 50000,
                validation: true,
                createNewAffaire: false,
                saving: false,
                deleting: false,

                agencesMatching: [],
                clientsMatching: [],
                affairesMatching: [],

                file2Add: null,
                linkedDocuments: [],
            }
        },

        beforeMount() {
            this.createNewAffaire = false;
            if(this.$route.params.hasOwnProperty("decId"))
                this.fetchDec();

            this.refreshStatuts();
            this.refreshTauxTvas();
            this.refreshClients();
            this.refreshAgences();
            this.refreshAffaires();
        },

        setup(props, {root}) {
            // TODO modifier le computed factory pour pouvoir spécifier des types pour les attributs :
            // resource, pour savoir qu'on doit réduire l'objet à juste son IRI (apiPlatform), float pour savoir
            // que l'on doit le convertir en float (sinon erreur enregistrement api platform)
            const currentBreakPoint = computed(() => root.$store.getters['app/currentBreakPoint'])
            // TODO voir si on ne peut pas récupérer tous les fields attendus pour un groupe de serialization via APi platform
            const storeFields = ["clients", "agences", "decStatuts", "affaireStatuts", "tauxTvas", "users"]
            let decBase = null;
            let creatingBase = !root.$route.params.hasOwnProperty("decId");
            if(creatingBase) {
                decBase = expectedFields.reduce((accumulator, field) => ({...accumulator, [field]: null}), {});
            }


            const dec = ref(decBase);
            const creating = ref(creatingBase)

            return {
                dec,
                creating,
                expectedFields,
                ...computedFactory(expectedFields, "dec"),
                ...storeComputedFactory(storeFields, root.$store),
                currentBreakPoint,
                required,
                confirmDeleteDec,
                deleteDec,
                formatNumber,
                deleteDocument, confirmDeleteDocument,
                downloadFile,
            }
        },

        computed: {
            dataMontantsEditHorsDebours() {
                let pourcentageAleas = this.pourcentageAleas;
                let pourcentageProrata = this.pourcentageProrata;
                return [
                    {
                        title: "Divers",
                        fields: [
                            {
                                id: 'ligneAleasCU',
                                title: "Aleas",
                                cu: {field: "ligneAleasCU", value: this.ligneAleasCU},
                                extras : {pourcentage: {value: pourcentageAleas, prefix: '%'}}
                            },
                            {
                                id: 'ligneCompteProrataCU',
                                title: "Compte Prorata",
                                cu: {field: "ligneCompteProrataCU", value: this.ligneCompteProrataCU},
                                extras : {pourcentage: {value: pourcentageProrata, prefix: '%'}}
                            }
                        ]
                    }
                ];
            },
            
            ...mapState({
                user: state => state.data.user
            }),
            ...mapGetters("data", ["showDecsOnlyUser", "isGranted"]),
            isChargeAffaire() {
                return this.isGranted("ROLE_CHARGE_AFFAIRE") && this.userValidationChargeAffaire?.id == this.user.id
            },
            deleteClass() {
                return (this.currentBreakPoint == "sm" || this.currentBreakPoint == "xs") ? "mt-1" : "text-right";
            },
            isBrouillon() {
                return this.statut.code == "BROUILLON"
            },
            getAffaireTitle() {
                return this.client == null ? "Veuillez sélectionne un client avant de sélectionner une affaire" : ""
            },
            decReouvrable() {return !this.isBrouillon && (this.isGranted("ROLE_DIRECTION") || (this.isGranted("ROLE_COMPTABILITE") && this.userValidationDirection == null))},

            tauxTvaSelect() {
                return this.tauxTvas.map(tva => ({value: tva, text: tva.taux}))
            },

            tva() {return (this.tauxTva.taux / 100) * parseFloat(this.prixVenteHT);},
            prixVenteTTC() {return this.tva + parseFloat(this.prixVenteHT)},

            totalFourniture() {return isNaN(parseFloat(this.ligneMaterielQte)) || isNaN(parseFloat(this.ligneMaterielQte)) ? 0 : parseFloat(this.ligneMaterielQte) * parseFloat(this.ligneMaterielCU)},
            totalSousTraitance() {return isNaN(parseFloat(this.ligneSousTraitanceQte)) || isNaN(parseFloat(this.ligneSousTraitanceCU)) ? 0 : parseFloat(this.ligneSousTraitanceQte) * parseFloat(this.ligneSousTraitanceCU)},
            totalFournitureSousTraitance() {return this.totalFourniture + this.totalSousTraitance},

            totalMainOeuvreLocale() {return isNaN(parseFloat(this.ligneMainOeuvreLocaleCU)) || isNaN(parseFloat(this.ligneMainOeuvreLocaleQte)) ? 0 : parseFloat(this.ligneMainOeuvreLocaleCU) * parseFloat(this.ligneMainOeuvreLocaleQte)},
            totalMainOeuvreDeplacee() {return isNaN(parseFloat(this.ligneMainOeuvreDeplaceeCU)) || isNaN(parseFloat(this.ligneMainOeuvreDeplaceeQte)) ? 0 : parseFloat(this.ligneMainOeuvreDeplaceeCU) * parseFloat(this.ligneMainOeuvreDeplaceeQte)},
            totalMainOeuvreInterim() {return isNaN(parseFloat(this.ligneMainOeuvreInterimCU)) || isNaN(parseFloat(this.ligneMainOeuvreInterimQte)) ? 0 : parseFloat(this.ligneMainOeuvreInterimCU) * parseFloat(this.ligneMainOeuvreInterimQte)},
            totalEtudes() {return isNaN(parseFloat(this.ligneEtudesCU)) || isNaN(parseFloat(this.ligneEtudesQte)) ? 0 : parseFloat(this.ligneEtudesCU) * parseFloat(this.ligneEtudesQte)},
            totalEssais() {return isNaN(parseFloat(this.ligneEssaisCU)) || isNaN(parseFloat(this.ligneEssaisQte)) ? 0 : parseFloat(this.ligneEssaisCU) * parseFloat(this.ligneEssaisQte)},
            totalMainOeuvre() {return this.totalMainOeuvreLocale + this.totalMainOeuvreDeplacee + this.totalMainOeuvreInterim + this.totalEtudes + this.totalEssais},
 
            totalEncadrement() {
                let maitrise = parseFloat(this.ligneTotalMaitriseCU);
                maitrise = isNaN(maitrise) ? 0 : maitrise;

                let chantier = parseFloat(this.ligneTotalFraisChantierCU)
                chantier = isNaN(chantier) ? 0 : chantier;

                let chargeAffaire = parseFloat(this.ligneTotalMaitriseEtudeCU)
                chargeAffaire = isNaN(chargeAffaire) ? 0 : chargeAffaire;

                let maitriseEtude = parseFloat(this.ligneTotalMaitriseChargeAffaireCU)
                maitriseEtude = isNaN(maitriseEtude) ? 0 : maitriseEtude;

                return maitrise + chantier + chargeAffaire + maitriseEtude;
            },

            totalDivers() {
                let aleas = parseFloat(this.ligneAleasCU);
                aleas = isNaN(aleas) ? 0 : aleas;

                let prorata = parseFloat(this.ligneCompteProrataCU)
                prorata = isNaN(prorata) ? 0 : prorata;

                return aleas + prorata;
            },

            totalDiversDebours() {
                let divers = parseFloat(this.ligneDiversCU);
                divers = isNaN(divers) ? 0 : divers;

                let outillage = parseFloat(this.ligneOutillageCU)
                outillage = isNaN(outillage) ? 0 : outillage;

                return divers + outillage;
            },

            totalPrixRevient() {return this.totalFournitureSousTraitance + this.totalMainOeuvre + this.totalEncadrement + this.totalDiversDebours},
            prixVenteInitial() {
                let devis = parseFloat(this.prixVenteHT)
                devis = isNaN(devis) ? 0 : devis;
                return devis - this.totalDivers;
            },

            margeEuros() {return this.prixVenteInitial - this.totalPrixRevient},
            margePourcent() {return this.prixVenteInitial == 0 ? 0 : this.margeEuros * 100 / this.prixVenteInitial},

            margeBaseEuros() {
                let devis = parseFloat(this.prixVenteHT)
                devis = isNaN(devis) ? 0 : devis;
                return devis - this.totalPrixRevient;
            },
            margeBasePourcent() {
                let devis = parseFloat(this.prixVenteHT)
                devis = isNaN(devis) ? 0 : devis;
                return devis == 0 ? 0 : this.margeBaseEuros * 100 / devis
            },

            usersChargeAffaireMatching() {return this.users.filter(user => user.roles.includes("ROLE_CHARGE_AFFAIRE"))},
            usersComptabiliteMatching() {return this.users.filter(user => user.roles.includes("ROLE_COMPTABILITE"))},
            usersDirectionMatching() {return this.users.filter(user => user.roles.includes("ROLE_DIRECTION"))},
            
            exportUrl() {
                if (this.dec != null)
                    return 'api/dec/' + this.dec.id + '/export';
                return '';
            },



            plafondDepasse() {
                let devis = parseFloat(this.prixVenteHT);
                devis = isNaN(devis) ? 0 : devis;
                return devis < this.plafondAffaire;
            },

            pourcentageProrata() {
                 return this.montantPourcentageVente(this.ligneCompteProrataCU);
            },

            pourcentageAleas() {
                return this.montantPourcentageVente(this.ligneAleasCU);
            },

        },

        methods: {
            reopenDec() {
                this.reopen = true;
                this.statut = this.decStatuts.find(s => s.code == "BROUILLON")
                //TODO sauvegarde pour que le chargé d'affaire puisse modifier ? obligé de passer par le bouton enregistrer après ?
            },
            removeDocument(documentId) {
                let index = this.linkedDocuments.findIndex(d => d.id == documentId)
                if(index != -1)
                    this.linkedDocuments.splice(index, 1)
            },
            filterAgences(options, search) {
                return filterArray(options, ["numero", "nom"], search)
            },
            filterClients(options, search) {
                return filterArray(options, ["nom"], search)
            },
            filterUsers(options, search) {
                return filterArray(options, ["firstname", "lastname", "username"], search)
            },
            filterAffaires(options, search) 
            {
                return filterArray(options, ["num","titre"], search)
            },

            goBack() {
                // ! ATTENTION => fonctionne dans le cas retour à la liste. si on utilise le composant Dec à un autre endroit
                // ! il faudra surement revoir la logique. a garder en tête.
                this.$router.go(-1)
            },

            async fetchDec() {
                this.dec = await decApi.getDec(this.$route.params.decId);
                if (this.affaire == null)
                    this.createNewAffaire = true;
                this.fetchDocuments();
            },

            async validationForm(validation = "") {
                let statut = null;
                if(validation != "") {
                    let {isConfirmed} = await this.$swal({
                        title: 'Valider Dec',
                        text: `Etes-vous sûr de vouloir valider le dec ?` + (this.showDecsOnlyUser ? 'Il ne vous sera plus possible de le modifier' : ''),
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonText: 'Valider',
                        cancelButtonText: "Annuler",
                        customClass: {
                            confirmButton: 'btn btn-success',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    })

                    if(isConfirmed) {
                        statut = this.decStatuts.find(s => s.code == validation)
                        this.validation = true;
                    }
                    else {
                        this.validation = false;
                        return;
                    }
                }
                else
                    this.validation = false;


                this.$nextTick(async () => {
                    let res = await this.$refs.simpleRules.validate()
                                    
                    if(res)
                        this.save(statut);
                    else {
                        this.showToast("danger", "Enregistrement impossible, veuillez vérifier les champs du formulaire", "AlertCircleIcon")
                        this.$nextTick(() => {
                            const el = this.$el.querySelector(".is-invalid");
                            if(el.id == "titre")
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            else  {
                                el.scrollIntoView({ behavior: 'smooth', block: 'center' }); //TODO à revoir ne fonctionne pas
                            }
                            return;
                        });
                    }
                })
            },
            
            async save(statut = null) {
                try {
                    this.saving = true;
                    if(this.createNewAffaire && statut != null) {
                        let canBeCreated = false;
                        //quel profil valide ? compta ou direction
                        //si compta il faut vérifier le plafond
                        //est-ce que le montant est supérieur au plafond ?
                        if (statut.code == 'DIRECTION' || (statut.code == 'COMPTABILITE' && this.plafondDepasse))
                            canBeCreated = true;

                        if (canBeCreated) {
                            let statutAffaireEncours = this.affaireStatuts.find(s => s.code == 'ENCOURS');
                            this.affaire = await affaireApi.addAffaire({client: this.client["@id"], statut: statutAffaireEncours["@id"], userChargeAffaire: this.userValidationChargeAffaire["@id"], agence: this.agence["@id"], titre: this.titre});
                            this.createNewAffaire = false;
                        }
                    }
                    let data = this.expectedFields.filter(field => this[field] != this.dec[field]).reduce((accumulator, currentValue) => ({...accumulator, [currentValue]: this[currentValue]}), {});
                    
                    data.totalDebours = this.totalPrixRevient;

                    if(statut != null)
                        data.statut = statut;

                    Object.entries(data).forEach(([key, value]) => {
                        if(value.hasOwnProperty("@id"))
                            data[key] = value['@id']
                        if(["prixVenteHT", ...decMontantsFields].includes(key))
                            data[key] = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
                    })
                    
                    if(this.reopen) {
                        // quand le DEC est réouvert, il repasse temporairement à "BROUILLON"
                        // mais on ne veut pas vraiment remettre le DEC à BROUILLON en base
                        delete data.statut;
                        //il ne faut pas changer les validations non plus
                        delete data.userValidationComptabilite;
                        delete data.userValidationDirection;
                    }
                    let callApi = this.creating ? () => decApi.addDec(data) : () => decApi.patchDec(this.dec.id, data)
                    if(Object.entries(data).length != 0) {
                        this.dec = await callApi();
                        this.statut = this.dec.statut;
                        this.initSelectUsers();
                        if(this.creating) {
                            this.$router.replace(`/decs/${this.dec.id}/edit`)
                            this.creating = false;
                        }
                        if (this.reopen)
                            this.reopen = false;
                    }

                    await this.refreshUsers();
                    this.showToast("success", this.creating ? "Dec créé" : "Dec mis à jour", "CheckIcon")
                    //this.$router.go(-1);
                } catch(e) {
                    console.log("e", e)
                    /* if(oldStatut != null)
                        this.statut = oldStatut */
                    this.showToast("danger", "Une erreur s'est produite", "AlertCircleIcon")
                }
                finally {
                    this.saving = false;
                }
            },

            // TODO déplacer dans service, en faire une méthode générique
            showToast(variant, text, icon) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        icon,
                        text,
                        variant
                    },
                }, {position: "bottom-right", timeout: 2500})
            },

            async refreshUsers() {
                this.users = await utilisateurApi.getUtilisateurs({active: 1});
                this.initSelectUsers();
            },

            initSelectUsers() {
                if(this.isGranted("ROLE_COMPTABILITE") && this.statut.code == "CHARGE_AFFAIRE" && this.userValidationComptabilite == null) {
                    this.userValidationComptabilite = this.users.find(u => u.id == this.user.id)
                }

                if(this.isGranted("ROLE_DIRECTION") && this.statut.code == "COMPTABILITE" && this.userValidationDirection == null) {
                    this.userValidationDirection = this.users.find(u => u.id == this.user.id)
                }

                if(this.creating && this.isGranted("ROLE_CHARGE_AFFAIRE") && this.userValidationChargeAffaire == null)
                    this.userValidationChargeAffaire = this.users.find(u => u.id == this.user.id)
            },

            async refreshStatuts() {
                this.decStatuts = await miscApi.getDecStatuts();
                this.affaireStatuts = await miscApi.getAffaireStatuts();
                if(this.creating) {
                    this.statut = this.decStatuts.find(s => s.code == "BROUILLON")
                }

                this.refreshUsers()
            },

            async refreshTauxTvas() {
                this.tauxTvas = await miscApi.getTauxTvas();
                if(this.creating)
                    this.tauxTva = this.tauxTvas.find(s => s.code == "20")
            },

            async refreshClients() {
                this.clients = await clientApi.getClients();
            },

            async refreshAgences() {
                this.agences = await agenceApi.getAgences();
            },

            async refreshAffaires() {
                if (this.client != null && this.agence != null)
                    this.affairesMatching = await affaireApi.getAffaires({client: this.client.id, agence: this.agence.id})
                else
                    this.affairesMatching = [];
            },

            fetchAgences: _.debounce(async function(search, loading) {
                if(search.length >= 2) {
                    loading(true)
                    this.agencesMatching = await agenceApi.getAgences({agence_select: search})
                    loading(false)
                }
            }, 500),

            fetchClients: _.debounce(async function(search, loading) {
                if(search.length >= 2) {
                    loading(true)
                    this.clientsMatching = await clientApi.getClients({nom: search})
                    loading(false)
                }
            }, 500),

            fetchAffaires: _.debounce(async function(search, loading) {
                if(search.length >= 2) {
                    loading(true)
                    this.affairesMatching = await affaireApi.getAffaires({num: search, client: this.client.id, agence: this.agence.id})
                    loading(false)
                }
            }, 500),

            async fetchDocuments() {
                if (this.dec != null && this.dec.id > 0)
                {
                    try {
                        let documents = await docApi.getDocuments({dec: this.dec.id});
                        this.linkedDocuments = documents;
                    }
                    catch(fetchDocException) 
                    {
                        console.log(fetchDocException);
                    }
                }
            },

            async addFile() {
                if (this.file2Add != null) 
                {
                    console.log(this.file2Add.name,this.dec);
                    try {
                        let newDoc = await docApi.addDocument(this.dec['@id'], this.file2Add);
                        this.linkedDocuments.push(newDoc);
                    }
                    catch(addFileException) {
                        console.log(addFileException);
                    }
                    this.file2Add = null;
                }
                
            },

            getDocUrl(doc) {
                let url = APP_URL + doc.path;
                return url;
            },

            montantPourcentageVente(montant) {
                montant = parseFloat(montant)
                montant = isNaN(montant) ? 0 : montant;
                if (montant > 0)
                {
                    let pvht = parseFloat(this.prixVenteHT);
                    pvht = isNaN(pvht) ? 0 : pvht;
                    return montant / pvht * 100
                }
                return 0;
            }
        }

    }
</script>

<style>
    .danger,
    .danger .vs__selected-options,
    .danger .vs__dropdown-toggle {
        color: red;
        border-color: red;
    }
</style>