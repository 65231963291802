import documentApi from "@/api/documentApi"

// TODO : au final confirmDelete et delete sont identiques pour tout nos objets, rendre cela encore plus générique
// avec une seule fonction
export async function confirmDeleteDocument(documentId, documentNom, deleteCallback = null, triggerLoading = true) {
    let {isConfirmed} = await this.$swal({
        title: 'Supprimer Document',
        text: `Etes-vous sûr de vouloir supprimer le document ${documentNom}`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirmer',
        cancelButtonText: "Annuler",
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
    })

    if(isConfirmed)
        await this.deleteDocument(documentId, deleteCallback, triggerLoading);
}

export async function deleteDocument(documentId, deleteCallback = null, triggerLoading = true) {
    if(this.hasOwnProperty("deleting") && triggerLoading)
        this.deleting = true;
    try {
        await documentApi.deleteDocument(documentId);
        this.showToast("success", "Document supprimé", "CheckIcon")
        if(deleteCallback != null && typeof deleteCallback === "function")
            deleteCallback(documentId);
        
    } catch(e) {
        console.log("e", e)
        this.showToast("danger", "Une erreur s'est produite", "AlertCircleIcon")
    }
    finally {
        if(this.hasOwnProperty("deleting") && triggerLoading)
            this.deleting = false;
    }
}